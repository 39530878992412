import tw from 'tailwind-styled-components';
import { animated, to } from 'react-spring';

import useWindowSize from '@/hooks/use-window-size';

const Container = tw(animated.div)`
  h-screen
  fixed
  left-0
  pointer-events-none
  w-screen
  top-0
  -z-10
`;

const Background = tw(animated.div)`
  absolute
  h-full
  left-0
  top-0
  w-full
  transform-gpu
  will-change-opacity
`;

export default function AnimatedBackground ({ colors = ['transparent'], scrollY }) {
  const { vh } = useWindowSize();

  return (
    <Container>
      {colors.map((backgroundColor, index) => (
        <Background
          key={index}
          style={{
            backgroundColor,
            opacity: to(scrollY, value => {
              const opacity = (value - (vh * (index - 1))) / vh;
              return isNaN(opacity) ? 0.0 : opacity;
            })
          }}
        />
      ))}
    </Container>
  );
}
