import { memo } from 'react';
import dynamic from 'next/dynamic';
import tw from 'tailwind-styled-components';
import { Title as TitleBase, SubTitle as SubTitleBase } from '@bidalihq/ui';

import AppStoreBadges from '@/components/app-store-badges';

// const Cards = dynamic(() => import('@/components/app/cards'), { ssr: false });

const Container = tw.div`
  absolute
  flex
  flex-col
  h-screen
  items-center
  justify-center
  overflow-hidden
  w-screen
  z-[1000]

  md:overflow-visible
  md:pt-28
  md:z-auto
`;

const Title = tw(TitleBase)`
  mt-24
  text-white
  text-center
  relative
  text-5xl
  font-inter
  z-20
  -skew-y-3

  md:mt-0
  md:text-6xl
  xl:text-7xl
`;

const Text = tw.span`
  box-decoration-clone
  backdrop-blur-sm
  px-4
  py-2
  z-20
`;

const SubTitle = tw(SubTitleBase)`
  font-semibold
  leading-5
  mt-4
  text-center
  relative
  z-20
  -skew-y-3
`;

function Hero () {
  return (
    <Container>
      {/* <Cards cardsPerRing={8} rings={5} ringsToSkip={3} /> */}
      <Title><Text className="bg-pink-500/80">From Here to Anywhere</Text></Title>
      <SubTitle className="text-brand-accent mb-12 md:mb-0"><Text className="bg-white/90">Gift Cards That Close the Distance</Text></SubTitle>
      <SubTitle className="text-white mt-16 mb-8"><Text className="bg-brand-primary/75">Discover a new way to pay and save</Text></SubTitle>
      <AppStoreBadges className="z-20" />
    </Container>
  );
}

export default memo(Hero);
