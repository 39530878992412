import { Grid, Column } from '@bidalihq/ui';
import Image from 'next/image';
import tw from 'tailwind-styled-components';
import clsx from 'clsx';

import ButtonBase from '@/components/button';
import config from '@/config';

const Button = tw(ButtonBase)`
  !hover:shadow-none
  hover:translate-y-0
  !p-0
  rounded-none
`;

const AppStoreBadges = ({ className, mono }) => {
  return (
    <Grid columns={[1, 2]} className={clsx('text-center md:text-inherit gap-0 md:gap-4', className)}>
      <Column className="py-2 md:px-2">
        <Button variant="ghost" to={config.appDownloadUrl.production.ios} gaLabel="ios_app_download">
          <Image
            unoptimized
            alt="Download the Bidali app for iOS."
            height={48}
            src="/images/badges/app-store.svg"
            width={160} />
        </Button>
      </Column>
      <Column className="py-2 md:px-2">
        <Button variant="ghost" to={config.appDownloadUrl.production.android} gaLabel="android_app_download">
          {mono &&
            <Image
              unoptimized
              alt="Google Play Store Badge"
              height={48}
              src="/images/badges/play-store-mono.svg"
              width={160} />
          }

          {!mono &&
            <Image
              unoptimized
              alt="Google Play Store Badge"
              height={48}
              src="/images/badges/play-store.svg"
              width={160} />
          }
        </Button>
      </Column>
    </Grid>
  );
};

export default AppStoreBadges;
