import { useEffect } from 'react';
import tw from 'tailwind-styled-components';
import { animated, useInView, useTrail, useSpring, useSpringRef } from 'react-spring';
import { Grid as GridBase, Column, Title as TitleBase, SubTitle as SubTitleBase } from '@bidalihq/ui';
import { useInView as useIntersectionObserver } from 'react-intersection-observer';
import clsx from 'clsx';

import useWindowSize from '@/hooks/use-window-size';
import Phone from '@/components/phone';
import AppStoreBadges from '@/components/app-store-badges';
import { colors } from '@/themes';
import Video from '@/components/video';

const Title = tw(TitleBase)`
  xl:text-8xl
  font-inter
`;

const Paragraph = tw(SubTitleBase)`
  text-2xl
`;

const Container = tw(GridBase)`
  gap-0
  h-screen
  relative
  snap-center
  w-screen

  md:gap-4
`;

const Circles = tw(animated.div)`
  absolute
  h-full
  origin-center
  flex
  justify-center
  items-center
  w-full

  xl:w-1/2
`;

const Svg = tw.svg`
  absolute
  aspect-square
  left-1/2
  top-1/2
  -translate-x-1/2
  -translate-y-1/2
  scale-[2]

  lg:scale-150
  h-full
  w-auto
`;

const Blob = tw(animated.path)``;

const LeftContent = tw(Column)`
  relative
  flex
  flex-col
  items-center
  justify-center

  md:px-16
  lg:px-12
  lg:items-end
  xl:px-0
`;

const RightContent = tw(Column)`
  flex
  items-center
  flex-col
  text-white
  justify-start
  z-20
  text-center
  mix-blend-exclusion
  px-4

  md:-translate-x-8
  md:justify-center
  md:h-full
  lg:pr-8
  lg:pl-0
`;

export default function DownloadApp () {
  const { isPortrait, isLandscape } = useWindowSize();
  const blobApi = useSpringRef();
  const { blobShape } = useSpring({
    ref: blobApi,
    from: { blobShape: 'M 48.5,-57.2 C 63.3,-45.4,76,-30.5,78.5,-14.2 C 81,2.2,73.2,20,62.8,34.8 C 52.5,49.6,39.6,61.5,23.1,70.5 C 6.6,79.5,-13.4,85.7,-28.8,79.8 C -44.2,73.9,-54.9,55.9,-65,37.8 C -75,19.8,-84.3,1.6,-83.2,-16.4 C -82,-34.3,-70.4,-52.2,-54.8,-63.9 C -39.1,-75.6,-19.6,-81.2,-1.4,-79.6 C 16.8,-78,33.7,-69.1,48.5,-57.2 Z' },
    to: [
      { blobShape: 'M 58.3,-65.4 C 74.7,-55.7,86.5,-36.5,86.9,-17.8 C 87.3,1,76.2,19.3,64.4,34.7 C 52.5,50.2,40,62.7,24.8,68.4 C 9.6,74,-8.2,72.6,-23.7,66.4 C -39.3,60.2,-52.7,49.2,-63.3,34.7 C -73.9,20.2,-81.8,2.2,-81.2,-16.5 C -80.5,-35.2,-71.3,-54.6,-56.3,-64.5 C -41.4,-74.5,-20.7,-75,0.1,-75.2 C 20.9,-75.3,41.8,-75.1,58.3,-65.4 Z' },
      { blobShape: 'M 51.7,-58.6 C 67.9,-48,82.5,-32.6,86.4,-14.6 C 90.4,3.3,83.6,23.8,72.4,40.5 C 61.2,57.1,45.7,69.9,27.4,77.7 C 9.1,85.5,-12,88.1,-30.5,82.1 C -49,76.1,-64.9,61.4,-72.6,43.9 C -80.2,26.5,-79.6,6.3,-75.2,-12.4 C -70.9,-31,-62.8,-48.1,-49.6,-59.2 C -36.5,-70.3,-18.2,-75.4,-0.2,-75.1 C 17.7,-74.8,35.5,-69.1,51.7,-58.6 Z' },
      { blobShape: 'M 50.4,-58.3 C 65.5,-47.5,78,-31.7,83,-13.2 C 88,5.4,85.6,26.7,75.5,43.2 C 65.5,59.8,48,71.6,29.2,77 C 10.5,82.4,-9.4,81.4,-27.8,75.2 C -46.3,69,-63.3,57.7,-74.3,41.5 C -85.3,25.3,-90.3,4.2,-87.1,-15.6 C -83.9,-35.5,-72.6,-54.3,-56.7,-64.9 C -40.8,-75.6,-20.4,-78.3,-1.4,-76.7 C 17.7,-75,35.4,-69.1,50.4,-58.3 Z' },
      { blobShape: 'M 48.5,-57.2 C 63.3,-45.4,76,-30.5,78.5,-14.2 C 81,2.2,73.2,20,62.8,34.8 C 52.5,49.6,39.6,61.5,23.1,70.5 C 6.6,79.5,-13.4,85.7,-28.8,79.8 C -44.2,73.9,-54.9,55.9,-65,37.8 C -75,19.8,-84.3,1.6,-83.2,-16.4 C -82,-34.3,-70.4,-52.2,-54.8,-63.9 C -39.1,-75.6,-19.6,-81.2,-1.4,-79.6 C 16.8,-78,33.7,-69.1,48.5,-57.2 Z' }
    ],
    loop: true,
    delay: 0,
    config: { tension: 10, friction: 2 }
  });

  const [ref, springs] = useInView(
    () => ({
      from: { transform: 'scale(0)' },
      to: { transform: 'scale(1)' }
    }),
    {
      amount: 'all',
      threshold: 0.5
    }
  );

  const [inViewRef, inView] = useIntersectionObserver({ threshold: 0.9 });

  useEffect(() => {
    if (inView) {
      blobApi.start();
    } else {
      blobApi.stop();
    }
  }, [inView, blobApi]);

  const circleClassNames = clsx({ 'h-full w-auto': isLandscape, 'w-full h-auto': isPortrait });

  return (
    <div ref={inViewRef}>
      <Container columns={[1, 2, 3]}>
        <LeftContent span={1}>
          <Circles ref={ref} style={springs}>
            <Svg className={circleClassNames} viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <Blob fill={colors.brand.alt} d={blobShape} transform="translate(100 100)" />
            </Svg>
          </Circles>
          <Phone className="max-w-[50%] rounded-2xl md:rounded-[2.25em] md:max-w-sm aspect-[1/2.12]">
            <Video
              showAfter="intersection"
              className="rounded-xl md:rounded-[2.25em] h-full"
              poster="/images/screenshots/wallet/balance.webp"
              sources={[{ src: '/videos/bidali-app.webm' }, { src: '/videos/bidali-app.mp4' }]}
              size={{ height: 718, width: 331 }}
            />
          </Phone>
        </LeftContent>
        <RightContent span={[1, 1, 2]}>
          <Title>Ready to start saving?</Title>
          <Paragraph>Download the app today!</Paragraph>
          <AppStoreBadges mono />
        </RightContent>
      </Container>
    </div>
  );
}
