import { Children, cloneElement } from 'react';
import tw from 'tailwind-styled-components';
import { animated } from 'react-spring';

import clsx from 'clsx';

const Container = tw(animated.div)`
  bg-white
  border
  border-transparent
  shadow-xl
  rounded-[2.5em]
  inline-block
  p-[0.35em]

  supports-[backdrop-filter]:bg-[rgba(255,255,255,0.25)]
  supports-[backdrop-filter]:backdrop-blur-lg
  supports-[backdrop-filter]:border-[rgba(255,255,255,0.75)]
`;

const Image = tw.img`
  rounded-[2.25em]
`;

const Phone = ({ className, children, height, src, title, width }) => {
  return (
    <Container className={className}>
      {typeof src === 'string' && (
        <Image
          alt={title}
          height={height}
          src={src}
          title={title}
          width={width}
        />
      )}
      {!src &&
        Children.map(children, child =>
          cloneElement(child, {
            ...child.props,
            className: clsx('rounded-[2.25em]', child.props.className)
          })
        )
      }
    </Container>
  );
};

Phone.defaultProps = {
  height: 'auto',
  width: 'auto'
};

export default Phone;
