import { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useScroll } from 'react-spring';
import tw from 'tailwind-styled-components';
import merge from 'lodash.merge';
import Head from 'next/head';

import { withTracker } from '@/tracker';
import { colors } from '@/themes';
import useNavbarBackground from '@/hooks/use-navbar-background';
import AnimatedBackground from '@/components/animated-background';
import SEO from '@/components/seo';
import Globe from '@/components/app/globe';
import Hero from '@/components/app/hero';
import ClipPathImages from '@/components/app/clip-path-image';
import BorderlessGift from '@/components/app/borderless-gift';
import Allowance from '@/components/app/allowance';
import Cashback from '@/components/app/cashback';
import Charity from '@/components/app/charity';
import Remittance from '@/components/app/remittance';
import Rewards from '@/components/app/rewards';
import DownloadApp from '@/components/app/download-call-to-action';
import HowItWorks from '@/components/app/how-it-works';
import config from '@/config';

const Container = tw.div`
  max-w-[100vw]
  overflow-clip
`;

function App () {
  const [globeRendered, setGlobeRendered] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [borderlessInViewRef, borderlessInView] = useInView({ threshold: 0.75 });
  const [remittanceInViewRef, remittanceInView] = useInView({ threshold: 0.75 });
  const [location, setLocation] = useState([0, 0]);
  const clipPathImagesContainerRef = useRef(null);
  const { scrollY } = useScroll();

  useEffect(() => {
    const ua = navigator.userAgent;
    let OS;

    if (/android/i.test(ua)) {
      OS = 'Android';
    }

    if (/iPad|iPhone|iPod/.test(ua)) {
      OS = 'iOS';
    }

    setIsMobile(['Android', 'iOS'].includes(OS));
  }, []);

  useEffect(() => {
    if (borderlessInView) {
      setLocation([50.78, -120.412]);
    } else if (remittanceInView) {
      setLocation([24, 74.22]);
    } else {
      setLocation([0, 0]);
    }
  }, [borderlessInView, remittanceInView]);

  useNavbarBackground(`${colors.brand.primary}AA`, {
    container: {
      backdropFilter: 'blur(10px)',
      boxShadow: '0 0.0625em 2em 0 rgba(0, 0, 0, 0.125)',
      position: 'absolute',
      width: '100%'
    },
    navItem: { color: '#FFFFFF' },
    logo: { filter: 'grayscale(1) brightness(3)' }
  });

  const images = useMemo(() => [
    {
      src: '/images/allowance-b.webp',
      clipPath: [
        'polygon(0% 0%, 100% 0%, 100% 70%, 0% 100%)',
        'polygon(0% 0%, 100% 0%, 70% 100%, 0% 100%)'
      ],
      align: 'start'
    },
    {
      src: '/images/cashback-b.webp',
      clipPath: [
        'polygon(0% 0%, 100% 0%, 100% 100%, 0% 70%)',
        'polygon(0% 0%, 70% 0%, 100% 100%, 0% 100%)'
      ],
      align: 'start'
    },
    {
      src: '/images/rewards.webp',
      clipPath: [
        'polygon(0% 30%, 100% 0%, 100% 100%, 0% 100%)',
        'polygon(0% 0%, 100% 0%, 100% 100%, 30% 100%)'
      ],
      align: 'end'
    },
    {
      src: '/images/charity.webp',
      clipPath: [
        'polygon(0% 0%, 100% 30%, 100% 100%, 0% 100%)',
        'polygon(30% 0%, 100% 0%, 100% 100%, 0% 100%)'
      ],
      align: 'end'
    }
  ], []);

  return (
    <Container>
      <Head>
        <link rel="preload" href="/images/globe/america-384.webp" as="image" />
      </Head>
      <SEO
        title="Discover a new way to pay and save"
        description="Download Bidali for iOS and Android and save up to 10%."
        imageUrl={`${config.rootUrl}/images/social/app/general-share-image-1200x628.png`}
      />
      <AnimatedBackground
        colors={[
          colors.brand.primary,
          colors.brand.primary,
          colors.brand.accent,
          'rgb(99, 66, 191)',
          colors.status.danger,
          colors.status.success,
          colors.status.warning,
          '#F5F8FD'
        ]}
        scrollY={scrollY}
      />
      <div>
        <Hero globeRendered={globeRendered} />
        <Globe
          isMobile={isMobile}
          location={location}
          onRender={setGlobeRendered}
          scrollY={scrollY} />
        <BorderlessGift ref={borderlessInViewRef} />
        <Remittance ref={remittanceInViewRef} />
      </div>
      <div ref={clipPathImagesContainerRef}>
        <ClipPathImages
          data={images}
          containerRef={clipPathImagesContainerRef.current}
          scrollY={scrollY} />
        <Allowance />
        <Cashback />
        <Rewards />
        <Charity />
      </div>
      <HowItWorks scrollY={scrollY} />
      <DownloadApp scrollYCompensationFactor={6} />
    </Container>
  );
}

export default withTracker(App);

export async function getStaticProps() {
  return merge(
    { props: { htmlClassName: 'scroll-snap' } }
  );
}
