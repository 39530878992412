import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { animated, useSpring, to } from 'react-spring';
import tw from 'tailwind-styled-components';
import { useMediaQuery } from '@react-hook/media-query';
import { screens } from 'tailwindcss/defaultTheme';
import clsx from 'clsx';
import Image from 'next/image';

import useWindowSize from '@/hooks/use-window-size';

const Container = tw(animated.div)`
  clip-path-images
  w-screen
  h-screen
  sticky
  top-0
  mix-blend-hard-light
  overflow-hidden
`;

const ImageMask = tw(animated.div)`
  relative
  w-[calc(100%-2rem)]
  h-1/2
  m-4
  overflow-hidden
  will-change-transform

  md:w-2/3
  md:h-[calc(100%-2rem)]
`;

const ImageBackground = tw(animated.div)`
  absolute
  bg-center
  bg-cover
  bg-no-repeat
  h-full
  left-0
  top-0
  w-full
  transform-gpu

  md:transition-[border-radius]
`;

const ClipPathImages = memo(({ containerRef, data, scrollY }) => {
  const isMobile = useMediaQuery(`only screen and (max-width: ${screens.sm})`);
  const [currentImage, setCurrentImage] = useState(null);
  const [prevTranslateX, setPrevTranslateX] = useState('0%');
  const [offsetTop, setOffsetTop] = useState(containerRef?.offsetTop || 0);
  const { vh } = useWindowSize();
  const images = useRef([]);
  const alignment = data[currentImage]?.align;
  const clipPathIndex = isMobile ? 0 : 1;
  const frameId = useRef(null);

  const widthClassName = clsx({
    'w-[calc(100%-2rem)]': alignment === 'start',
    'w-full': alignment === 'end'
  })

  const imagePosition = useMemo(() => {
    const value = isMobile ? '100%' : '50%';

    if (alignment === 'end') {
      setPrevTranslateX(value);
      return value;
    }
    if (alignment === 'start') {
      setPrevTranslateX('0%');
      return '0%';
    }

    return prevTranslateX;
  }, [alignment, prevTranslateX, isMobile]);

  const clipPathSpring = useSpring({
    clipPath: data[currentImage]?.clipPath[clipPathIndex] || data[0]?.clipPath[clipPathIndex] || 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
    config: { tension: 200, friction: 20 }
  });

  const translateSpring = useSpring({
    transform: isMobile
      ? alignment === 'end'
        ? `translate3d(0%, calc(${imagePosition} - 2rem), 0) scale(1.5)`
        : `translate3d(0%, calc(${imagePosition} - 0rem), 0) scale(1.5)`
      : `translate3d(${imagePosition}, calc(0% - 0rem), 0) scale(1)`,
    config: { tension: 200, friction: 40 }
  });

  const handleScroll = useCallback(() => {
    const scrollPos = Math.max(window.scrollY - offsetTop, 0);
    const activeImage = Math.floor(scrollPos / vh + 0.5);

    setCurrentImage(
      images.current[activeImage] ? activeImage : null
    );

    frameId.current = requestAnimationFrame(handleScroll);
  }, [offsetTop, vh]);

  useEffect(() => {
    if (containerRef) {
      setOffsetTop(containerRef.offsetTop);
    }

    frameId.current = requestAnimationFrame(handleScroll);

    return () => {
      if (frameId.current) {
        cancelAnimationFrame(frameId.current);
      }
    };
  }, [containerRef, vh, handleScroll]);

  return (
    <Container
      style={{
        opacity: to(scrollY, value => {
          const scrollPos = Math.max(value - offsetTop + vh, 0);
          const opacity = 1 - (((scrollPos - vh * data.length) / vh) * 5);
          return isNaN(opacity) ? 0.0 : opacity;
        })
      }}
    >
      <ImageMask
        className={widthClassName}
        style={{
          ...clipPathSpring,
          ...translateSpring
        }}
      >
        {data.map(({ src }, index) => (
          <ImageBackground
            key={index}
            ref={el => images.current[index] = el}
            style={{
              opacity: to(scrollY, value => {
                const scrollPos = Math.max(value - offsetTop + vh, 0);
                const opacity = (scrollPos - (vh * index)) / vh;
                return isNaN(opacity) ? 0.0 : opacity;
              }),
              transform: alignment === 'end'
                ? 'translate3d(-2rem, 0, 0)'
                : 'translateX(0rem, 0, 0)',
            }}>
              <Image className="aspect-square object-cover" src={src} fill sizes="50vw" alt="" />
          </ImageBackground>
        ))}
      </ImageMask>
    </Container>
  );
});

ClipPathImages.displayName = 'ClipPathImages';

export default ClipPathImages;
