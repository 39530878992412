import { useEffect, useState } from 'react';

export default function useWindowSize () {
  const [windowSize, setWindowSize] = useState({
    vw: typeof window === 'undefined' ? 1 : window.innerWidth,
    vh: typeof window === 'undefined' ? 1 : window.innerHeight
  }); // Initialize to 1 to prevent divide by 0 errors, just in case
  const isPortrait = windowSize.vw < windowSize.vh;
  const isSquare = windowSize.vw === windowSize.vh;
  const isLandscape = windowSize.vw > windowSize.vh;

  useEffect(() => {
    const onResize = () => {
      setWindowSize({
        vh: window.innerHeight,
        vw: window.innerWidth
      });
    };

    onResize();

    window.addEventListener('resize', onResize, { passive: true });

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  return {
    ...windowSize,
    isPortrait,
    isLandscape,
    isSquare
  };
}
