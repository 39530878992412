import { forwardRef, useImperativeHandle, useRef } from 'react';
import tw from 'tailwind-styled-components';
import { animated, useInView } from 'react-spring';
import { Title as TitleBase, SubTitle as SubTitleBase, Paragraph as ParagraphBase } from '@bidalihq/ui';
import Lottie from 'lottie-react';

import remittance from '@/lottie/remittance.json';

const Container = tw.div`
  [-webkit-backface-visibility:hidden;]
  flex
  items-center
  h-screen
  relative
  z-20
  py-0
  md:py-0
  snap-center
`;

const Content = tw(animated.div)`
  flex
  flex-col
  justify-center
  text-gray-50
  px-4
  pt-4
  mb-auto
  will-change-transform

  md:mb-0
  md:h-full
  md:w-1/2
  md:ml-auto
  md:pr-8
  lg:pr-12
  lg:text-brand-accent
  xl:pr-32
`;

const Title = tw(TitleBase)`
  font-inter
  lg:-skew-y-3
  xl:text-8xl
`;

const SubTitle = tw(SubTitleBase)`
  text-2xl
  mb-12

  lg:-skew-y-3
  xl:text-6xl
`;

const Paragraph = tw(ParagraphBase)`
  md:text-2xl
  md:leading-10
  lg:-skew-y-3
`;

const Text = tw.span`
  box-decoration-clone
  px-4
  py-2
  z-20

  lg:bg-white
`;

const ContentOnGlobe = tw.div`
  flex
  items-center
  justify-center
  absolute
  top-1/3
  translate-y-[calc(50%+5vh)]
  scale-150

  lg:w-1/2
  lg:h-full
  lg:left-1/2
  lg:top-auto
  lg:translate-y-0
  lg:translate-x-[calc(-50%-30vw)]
  lg:scale-100
`;

function Remittance (_, ref) {
  const refContainer = useRef();
  const lottieRef = useRef();
  const isPlaying = useRef(false);

  const [inViewRef, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        transform: 'translate3d(25%, 0, 0)'
      },
      to: {
        opacity: 1,
        transform: 'translate3d(0%, 0, 0)'
      },
      onRest: () => {
        if (!isPlaying.current) {
          lottieRef.current.goToAndPlay(0);
          isPlaying.current = true;
        }
      }
    }),
    { amount: 0.5 }
  );

  const handleAnimationComplete = () => {
    setTimeout(() => lottieRef.current?.goToAndPlay(150, true), 3000);
  };

  useImperativeHandle(ref, () => refContainer.current);

  return (
    <Container ref={refContainer}>
      <ContentOnGlobe>
      <Lottie
        animationData={remittance}
        autoplay={false}
        loop={false}
        lottieRef={lottieRef}
        onComplete={handleAnimationComplete}
      />
      </ContentOnGlobe>
      <Content ref={inViewRef} style={springs}>
        <Title><Text>Home Ties with Digital Ease</Text></Title>
        <SubTitle><Text>Sending love, now just a tap away.</Text></SubTitle>
        <Paragraph><Text>Connect with your roots no matter where you are. With our digital gift cards, send funds back home effortlessly, ensuring your loved ones always have what they need.</Text></Paragraph>
      </Content>
    </Container>
  );
}

export default forwardRef(Remittance);
