import { memo, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import tw from 'tailwind-styled-components';
import { animated, to } from 'react-spring';
import NextImage from 'next/image';

import useWindowSize from '@/hooks/use-window-size';

const GlobeContainer = tw(animated.div)`
  globe
  [-webkit-backface-visibility:hidden;]
  flex
  h-screen
  items-center
  justify-center
  overflow-hidden
  sticky
  top-0
  transform-gpu
  w-screen
  will-change-transform
  z-10

  lg:p-12
`;

const ImageWrapper = tw(animated.div)`
  aspect-square
  relative
  transition-opacity
  will-change-[transform,_opacity]

  ${props => props.$isMobile ? `w-screen h-auto` : `h-screen w-auto`}
`;

const Image = tw(NextImage)`
  [filter:drop-shadow(0_0_2rem_rgba(255,255,255,0.75))]
  duration-1000
  scale-90
  transform-gpu
  transition-opacity

  lg:scale-[0.8]
`;

const UnoptimizedImage = tw.img`
  shadow-[0_0_3rem_rgba(255,255,255,0.75)]
  duration-1000
  scale-90
  transition-opacity
  absolute
  h-full
  w-full
  inset-0
  text-transparent
  aspect-square
  bg-gray-50
  rounded-full
  transform-gpu

  lg:scale-[0.8]
`;

function Globe ({ isMobile, location = [0, 0], onRender, scrollY }) {
  const [rendered, setRendered] = useState(false);
  const focusRef = useRef(location);
  const imageContainerRef = useRef();
  const { vh, isPortrait, isLandscape } = useWindowSize();
  const isAmerica = location.join(',') === '0,0';
  const isCalgary = location.join(',') === '50.78,-120.412';
  const isIndia = location.join(',') === '24,74.22';

  const mobileTransformSpring = to(scrollY, value => {
    const page = value / vh;

    if (page >= 2) {
      const compensatedScroll = value - (vh * 2);

      return `
        translate3d(0vw, 30vh, 0)
        scale(${Math.max(2 - compensatedScroll / 750, 0.5)})
      `;
    }

    if (value > 10 && value < vh * 3) {
      return `
        translate3d(0vw, ${Math.min(value / 15, 30)}vh, 0)
        scale(${Math.min((value / 750) + 1, 2)})
      `;
    } else if (value < 10) {
      return `
        translate3d(0vw, 0vh, 0)
        scale(1)
      `;
    }
  });

  const desktopTransformSpring = to(scrollY, value => {
    const page = value / vh;

    if (page >= 2) {
      const compensatedScroll = value - (vh * 2);

      return `
        translate3d(-30vw, 0vh, 0)
        scale(${Math.max(1.5 - compensatedScroll / 750, 0.5)})
      `;
    }

    if (value > 10 && value < vh * 3) {
      return `
        translate3d(-${Math.min(value / 15, 30)}vw, 0vh, 0)
        scale(${Math.min((value / 750) + 1, 1.5)})
      `;
    } else if (value < 10) {
      return `
        translate3d(0vw, 0vh, 0)
        scale(1)
      `;
    }
  });

  const imageContainerClassNames = clsx({
    'opacity-0': !rendered,
    'opacity-100': rendered,
    'h-full w-auto': isLandscape,
    'w-full h-auto': isPortrait || isMobile
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRendered(true);
    }, 250);

    return () => clearTimeout(timeout);
  }, [])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      focusRef.current = location;
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [location]);

  useEffect(() => {
    if (typeof onRender === 'function') {
      onRender(true);
    }
  }, [onRender]);

  return (
    <GlobeContainer
      style={{
        mixBlendMode: to(scrollY, value => {
          const page = value / vh;

          if (page >= 1) {
            return 'overlay';
          }

          return 'unset';
        }),
        opacity: to(scrollY, value => {
          const page = value / vh;
          const opacity = Math.max(1 - page, 0.4325); // Arbitrary number to make switching mixBlendMode less jarring

          if (isNaN(opacity) || isNaN(value) || isNaN(vh) || isNaN(page)) {
            return 0.0;
          }

          if (value >= vh) {
            return 1.0;
          }

          return opacity;
        })
      }}
    >
      <ImageWrapper
        className={imageContainerClassNames}
        $isMobile={isMobile}
        ref={imageContainerRef}
        style={{
          transform: isPortrait ? mobileTransformSpring : desktopTransformSpring
        }}
      >
        <UnoptimizedImage
          alt="Globe"
          className={clsx('z-30', { 'opacity-0': !isAmerica, 'opacity-100': isAmerica })}
          src="/images/globe/america-384.webp"
          srcSet="/images/globe/america-384.webp 384w, /images/globe/america-640.webp 640w, /images/globe/america-750.webp 750w, /images/globe/america-828.webp 828w, /images/globe/america-1080.webp 1080w, /images/globe/america-1200.webp 1200w, /images/globe/america.webp 1920w"
          height={1800}
          width={1800}
        />
        {scrollY.get() > 10 &&
          <Image
            alt="Globe"
            className={clsx('z-20', { 'opacity-0': !isCalgary, 'opacity-100': isCalgary })}
            fill
            priority
            sizes="50vw"
            src="/images/globe/calgary.webp"
          />
        }
        {(isCalgary || isIndia || (isAmerica && scrollY.get() > vh)) &&
          <Image
            alt="Globe"
            className={clsx('z-10', { 'opacity-0': !isIndia, 'opacity-100': isIndia })}
            fill
            priority
            sizes="50vw"
            src="/images/globe/india.webp"
          />
        }
      </ImageWrapper>
    </GlobeContainer>
  );
}

// const locationToAngles = ([lat, long]) => {
//   if (lat === 0 && long === 0) {
//     return [0, 0];
//   }

//   return [Math.PI - ((long * Math.PI) / 180 - Math.PI / 2), (lat * Math.PI) / 180];
// };

export default memo(Globe);
