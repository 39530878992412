import tw from 'tailwind-styled-components';
import { animated, useInView } from 'react-spring';
import { Column as ColumnBase, Grid as GridBase, Title as TitleBase, SubTitle as SubTitleBase, Paragraph as ParagraphBase } from '@bidalihq/ui';

const Container = tw.div`
  h-screen
  relative
  z-20
  py-0
  md:py-0
  snap-center
`;

const Content = tw(animated.div)`
  px-8
  text-gray-50
  will-change-transform

  md:px-0
  md:flex
  md:flex-col
  md:h-full
  md:justify-center
  lg:text-[#F2C94C]
`;

const Grid = tw(GridBase)`
  h-full
`;

const Column = tw(ColumnBase)`
  h-auto
  flex
  flex-col
  pt-8

  md:pt-0
  md:block
  md:h-full
  md:ml-8
  lg:ml-12
  xl:ml-32
`;

const EmptyColumn = tw(ColumnBase)`
  h-auto
  md:h-full
  relative
`;

const Title = tw(TitleBase)`
  font-inter
  mt-0
  xl:text-8xl
  lg:-skew-y-3
`;

const SubTitle = tw(SubTitleBase)`
  text-3xl
  mb-6

  md:mb-12
  lg:-skew-y-3
  xl:text-6xl
`;

const Paragraph = tw(ParagraphBase)`
  md:text-2xl
  md:leading-10
  lg:-skew-y-3
`;

const Text = tw.span`
  box-decoration-clone
  px-4
  py-2
  z-20

  lg:bg-white
`;

export default function Rewards () {
  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        transform: 'translate3d(-25%, 0, 0)'
      },
      to: {
        opacity: 1,
        transform: 'translate3d(0%, 0, 0)'
      }
    }),
    { amount: 0.75 }
  );

  return (
    <Container>
      <Grid className="md:gap-12" columns={[1, 2]}>
        <Column>
          <Content ref={ref} style={springs}>
            <Title><Text>Digital Gifts, A Heart of Gold</Text></Title>
            <SubTitle><Text>Every tap, a better world.</Text></SubTitle>
            <Paragraph><Text>Be the change you wish to see. Donate to your favorite causes or kickstart a fundraiser with digital gift cards. Together, let&apos;s create ripples of goodness.</Text></Paragraph>
          </Content>
        </Column>
        <EmptyColumn>
        </EmptyColumn>
      </Grid>
    </Container>
  );
}
