import Head from 'next/head';

const SEO = ({ title = '', description, imageUrl, keywords, url }) => {
  const pageTitle = typeof title === 'string'
    ? `Bidali | ${title}`
    : 'Bidali';

  return (
    <Head>
      {title && <>
        <title key="title">{pageTitle}</title>
        <meta name="twitter:title" content={title} key="twittertitle" />
        <meta property="og:title" content={title} key="ogtitle" />
        <meta itemProp="about" content={title} key="itempropabout" />
      </>}

      {description && <>
        <meta name="description" content={description} key="description"/>
        <meta name="twitter:description" content={description} key="twitterdescription" />
        <meta property="og:description" content={description} key="ogdescription" />
        <meta itemProp="description" content={description} key="itempropdescription" />
      </>}

      {imageUrl && <>
        <meta name="twitter:image:src" content={imageUrl} key="twitterimage" />
        <meta property="og:image" content={imageUrl} key="ogimage" />
        {title && <meta property="og:image:alt" content={title} key="ogimagealt" />}
        <meta itemProp="image" content={imageUrl} key="itempropimage" />
      </>}

      {url && <>
        <meta property="og:url" content={url} key="ogurl" />
      </>}

      {keywords && <>
        <meta name="keywords" content={keywords} key="keywords" />
      </>}
    </Head>
  );
};

export default SEO;
