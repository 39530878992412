import { memo, useCallback, useEffect, useRef, useState } from 'react';
import NextImage from 'next/image';
import { SubTitle as SubTitleBase, Title as TitleBase, Paragraph as ParagraphBase } from '@bidalihq/ui';
import tw from 'tailwind-styled-components';
import { useInView as useIntersectionObserver } from 'react-intersection-observer';
import { animated, to } from 'react-spring';
import { useMediaQuery } from '@react-hook/media-query';
import { screens } from 'tailwindcss/defaultTheme';
import clsx from 'clsx';

import useWindowSize from '@/hooks/use-window-size';

const Container = tw.div`
  how-it-works
  [background-image:url('/images/how-it-works-background.svg')]
  bg-no-repeat
  bg-cover
  flex
  items-start
  relative
  z-10
`;

const TitleContent = tw(animated.div)`
  text-center
  py-4
  px-4
  text-typography
  will-change-transform

  lg:min-w-[45vw]
`;

const Title = tw(TitleBase)`
  text-brand-primary
  font-inter

  md:text-6xl
  xl:text-8xl
`;

const SubTitle = tw(SubTitleBase)`
  mb-8

  md:text-3xl
  md:max-w-lg
  md:mx-auto
`;

const ImageContainer = tw.div`
  aspect-[4/3]
  min-h-[300px]
  mx-auto
  my-auto
  relative
  rounded-xl
  w-auto

  md:max-w-[80%]
  md:mt-4
  md:mb-0
  lg:max-w-[60%]
  lg:my-auto
  xl:w-auto
`;

const Image = tw(NextImage)`
  object-contain
`;

const Step = tw(animated.div)`
  bg-white
  flex
  flex-col
  flex-shrink-0
  h-auto
  rounded-xl
  shadow-2xl
  will-change-transform
  w-full

  md:w-[calc(50%-1rem)]
  lg:w-[40%]
  lg:min-h-[600px]
`;

const StepCount = tw.h2`
  flex
  h-32
  items-start
  justify-start
  pt-4
  pl-6
  mr-4
  mt-0
  shrink-0
  text-7xl
  text-brand-primary
  w-32
  absolute
  rounded-tl-xl
  overflow-hidden
  leading-none
  font-black
  z-10

  before:absolute
  before:bg-brand-primary/10
  before:w-[200%]
  before:h-[200%]
  before:top-0
  before:left-0
  before:rounded-full
  before:-translate-x-1/2
  before:-translate-y-1/2
`;

const StepContent = tw.div`
  p-4
  mt-4

  md:p-8
`;

const StepTitle = tw.h3`
  text-4xl
  font-bold
  mt-0

  md:text-3xl
  xl:text-4xl
`;

const Paragraph = tw(ParagraphBase)`
  mt-4
  text-xl
  text-typography/90

  md:text-lg
  xl:leading-10
  xl:text-2xl
`;

const ScrollingRow = tw.div`
  flex
  flex-col
  gap-8
  max-w-screen
  p-4

  md:gap-8
  md:p-8
  md:pb-20
  md:flex-row
  md:flex-wrap
  lg:overflow-hidden
  lg:items-center
  lg:sticky
  lg:h-screen
  lg:top-0
  lg:flex-nowrap
  xl:gap-12
  xl:p-24
`;

const HowItWorks = ({ scrollY }) => {
  const containerRef = useRef(null);
  const scrollerRef = useRef(null);
  const [scrollPos, setScrollPos] = useState(0);
  const [snapPoint, setSnapPoint] = useState('start');
  const [scrollHeight, setScrollHeight] = useState(0);
  const isPortraitTablet = useMediaQuery(`only screen and (max-width: ${screens.lg})`);
  const { vh, vw } = useWindowSize();

  const [inViewRef, inView] = useIntersectionObserver({
    threshold: 0.99, // 99% visibility
  });

  useEffect(() => {
    setScrollPos(scrollY.get());
  }, [scrollY]);

  useEffect(() => {
    if (inView) {
      document.documentElement.classList.remove('scroll-snap');
    } else {
      document.documentElement.classList.add('scroll-snap');
    }
  }, [inView]);

  useEffect(() => {
    const relativeScrollPos = scrollPos - (containerRef.current?.offsetTop || 0);

    if (relativeScrollPos < 100) {
      setSnapPoint('start');
    } else if (relativeScrollPos > scrollHeight - 100) {
      setSnapPoint('end');
    }
  }, [scrollPos, scrollHeight, containerRef]);

  useEffect(() => {
    setScrollHeight(vh + (scrollerRef.current?.scrollWidth - vw) || 0);
  }, [scrollerRef, vh, vw]);

  const sidewaysScroll = to(scrollY, value => {
    const compensatedScrollValue = value - (containerRef.current?.offsetTop || 0);

    if (compensatedScrollValue <= 0) {
      return `translate3d(0px, 0, 0)`;
    }

    return `translate3d(-${compensatedScrollValue}px, 0, 0)`;
  });

  const containerClassNames = clsx({
    'snap-start': snapPoint === 'start',
    'snap-end': snapPoint === 'end'
  });

  return (
    <Container
      className={containerClassNames}
      ref={el => { inViewRef(el); containerRef.current = el }}
      style={{ minHeight: `${scrollHeight}px` }}
    >
      <ScrollingRow ref={scrollerRef}>
        <TitleContent style={{ transform: isPortraitTablet ? 'translate3d(0px, 0, 0)' : sidewaysScroll }}>
          <Title>How It Works</Title>
          <SubTitle>Download the app, grab your digital gift card, and start saving!</SubTitle>
        </TitleContent>
        <Step style={{ transform: isPortraitTablet ? 'translate3d(0px, 0, 0)' : sidewaysScroll }}>
          <StepCount>1</StepCount>
          <ImageContainer>
            <Image fill sizes="100vw" src="/images/illustrations/cashback.png" alt="" />
          </ImageContainer>
          <StepContent>
            <StepTitle>Buy gift cards and get cash back</StepTitle>
            <Paragraph>Top up your Bidali Account balance and buy gift cards from thousands of local and international brands. After a successful purchase you&apos;ll instantly earn Bidali Cash <sup>†</sup> back.</Paragraph>
          </StepContent>
        </Step>

        <Step style={{ transform: isPortraitTablet ? 'translate3d(0px, 0, 0)' : sidewaysScroll }}>
          <StepCount>2</StepCount>
          <ImageContainer>
            <Image fill sizes="100vw" src="/images/illustrations/pay-in-store-or-online.png" alt="" />
          </ImageContainer>
          <StepContent>
            <StepTitle>Pay in store or online</StepTitle>
            <Paragraph>Use your digital gift cards to pay online or in store, or send them to a friend. You can always top up with additional gift cards or other payment methods. Such a great way to budget and save!</Paragraph>
          </StepContent>
        </Step>

        <Step style={{ transform: isPortraitTablet ? 'translate3d(0px, 0, 0)' : sidewaysScroll }}>
          <StepCount>3</StepCount>
          <ImageContainer>
            <Image fill sizes="100vw" src="/images/illustrations/send-bcash.png" alt="" />
          </ImageContainer>
          <StepContent>
            <StepTitle>Use your earned cash</StepTitle>
            <Paragraph>Stretch your money further and use your earned Bidali Cash to buy more gift cards or send to a friend. All for <strong>free</strong>. No catches or hidden fees.</Paragraph>
          </StepContent>
        </Step>

        <Step style={{ transform: isPortraitTablet ? 'translate3d(0px, 0, 0)' : sidewaysScroll }}>
          <StepCount>4</StepCount>
          <ImageContainer>
            <Image fill sizes="100vw" src="/images/illustrations/exclusive-offers.png" alt="" />
          </ImageContainer>
          <StepContent>
            <StepTitle>Collect exclusive offers</StepTitle>
            <Paragraph>Stack your savings by getting access to exclusive offers from local and international brands. Businesses are able to immediately send you credit that you can redeem alongside your gift cards and Bidali Cash. Win, win, win!</Paragraph>
          </StepContent>
        </Step>
      </ScrollingRow>
    </Container>
  );
};

export default memo(HowItWorks);
